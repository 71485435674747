var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-header',{staticClass:"pb-6",attrs:{"type":"primary"}},[_c('b-row',{staticClass:"align-items-center py-4"},[_c('b-col',{attrs:{"lg":"6","cols":"7"}},[_c('h6',{staticClass:"h2 text-white d-inline-block mb-0"},[_vm._v(_vm._s(_vm.title))])]),_c('b-col',{staticClass:"text-right",attrs:{"lg":"6","cols":"5"}},[(_vm.config.showAddNew)?_c('base-button',{attrs:{"size":"sm","type":"neutral"},on:{"click":function($event){_vm.config.onClickAddNew ? _vm.config.onClickAddNew() : null}}},[_vm._v("New")]):_vm._e(),_vm._t("controls")],2)],1)],1),_c('div',{staticClass:"container-fluid mt--6"},[_c('b-card',{staticClass:"mb-4",attrs:{"no-body":""}},[_c('b-card-body',[_vm._t("aboveFilter"),_c('b-row',[_vm._t("filters")],2),_c('b-row',[_vm._t("summary")],2),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.loading),expression:"loading",modifiers:{"fullscreen":true,"lock":true}}],staticClass:"table-responsive table-flush",attrs:{"element-loading-text":"Loading...","element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(0, 0, 0, 0.5) !important","header-row-class-name":"thead-light","data":_vm.entities.data}},[_c('el-table-column',{attrs:{"type":"index","label":"No.","width":"80","index":_vm.indexMethod}}),_vm._l((_vm.columns),function(ref,index){
                      var label = ref.label;
                      var key = ref.key;
                      var minWidth = ref.minWidth;
                      var formatAs = ref.formatAs;
return _c('el-table-column',{key:index,attrs:{"min-width":minWidth,"label":label,"prop":key},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var row = ref.row;
return [_vm._t(("cell_" + key),function(){return [(formatAs === 'currency')?_c('span',[_vm._v(" "+_vm._s(_vm.resolve(key, row) ? _vm.resolve(key, row).toLocaleString() : 0)+" ")]):_c('span',[_vm._v(_vm._s(_vm.resolve(key, row)))])]},{"entity":row})]}}],null,true)})}),(_vm.config.hasActions !== false)?_c('el-table-column',{attrs:{"label":"action"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var row = ref.row;
return [_vm._t("actions",null,{"entity":row}),(_vm.config.hasBasicActions !== false)?_c('el-tooltip',{attrs:{"placement":"top","content":"edit"}},[_c('base-button',{attrs:{"size":"sm","type":"info"},on:{"click":function($event){_vm.config.onClickEdit ? _vm.config.onClickEdit(row) : null}}},[_c('i',{staticClass:"fa fa-edit"})])],1):_vm._e(),(_vm.config.hasBasicActions !== false)?_c('el-tooltip',{attrs:{"placement":"top","content":"delete"}},[_c('base-button',{attrs:{"size":"sm","type":"danger"},on:{"click":function($event){return _vm.confirmDelete(row)}}},[_c('i',{staticClass:"fa fa-trash"})])],1):_vm._e()]}}],null,true)}):_vm._e()],2)],1)],1),(!_vm.config.hidePaginate)?_c('b-row',{staticClass:"mt-5"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-pagination',{attrs:{"total-rows":_vm.meta.total,"per-page":_vm.meta.per_page,"interval":"5","first-number":"","last-number":""},on:{"change":function (page) { return _vm.$emit('onPageChanged', page); }},model:{value:(_vm.meta.current_page),callback:function ($$v) {_vm.$set(_vm.meta, "current_page", $$v)},expression:"meta.current_page"}})],1),_c('b-col',{staticClass:"d-flex justify-content-end align-items-center",attrs:{"md":"6"}},[_c('span',{staticClass:"text-sm mr-2"},[_vm._v("Per Page")]),_c('el-select',{attrs:{"size":"mini","placeholder":"Simple select"},on:{"change":function (val) { return _vm.$emit('onLimitChanged', val); }},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}},_vm._l(([10, 20, 50]),function(option){return _c('el-option',{key:option,attrs:{"label":option,"value":option}})}),1)],1)],1):_vm._e()],2)],1)],1),_vm._t("others")],2)}
var staticRenderFns = []

export { render, staticRenderFns }