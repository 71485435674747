<template>
  <div>
    <base-header class="pb-6" type="primary">
      <b-row class="align-items-center py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">{{ title }}</h6>
          <!-- <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav> -->
        </b-col>
        <b-col lg="6" cols="5" class="text-right">
          <base-button
            v-if="config.showAddNew"
            @click="config.onClickAddNew ? config.onClickAddNew() : null"
            size="sm"
            type="neutral">New</base-button>

          <slot name="controls"></slot>
        </b-col>
      </b-row>
    </base-header>

    <div class="container-fluid mt--6">
      <b-card no-body class="mb-4">
        <b-card-body>
          <slot name="aboveFilter"></slot>
          <b-row>
            <slot name="filters"></slot>
          </b-row>
          <b-row>
            <slot name="summary"></slot>
          </b-row>
          <b-row>
            <b-col md="12">
              <el-table class="table-responsive table-flush"
                  v-loading.fullscreen.lock="loading"
                  element-loading-text="Loading..."
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(0, 0, 0, 0.5) !important"
                  header-row-class-name="thead-light"
                  :data="entities.data">
                  
                    <el-table-column
                      type="index"
                      label="No."
                      width="80"
                      :index="indexMethod">
                    </el-table-column>

                    <el-table-column
                        :min-width="minWidth"
                        v-for="({
                            label,
                            key,
                            minWidth,
                            formatAs
                        }, index) in columns"
                        :key="index"
                        :label="label"
                        :prop="key">

                        <template v-slot="{ row }">
                            <slot :name="`cell_${ key }`" :entity="row">
                              <span v-if="formatAs === 'currency'">
                                {{ resolve(key, row) ? resolve(key, row).toLocaleString() : 0 }}
                              </span>
                              <span v-else>{{ resolve(key, row) }}</span>
                            </slot>
                        </template>
                    </el-table-column>
                    <el-table-column label="action" v-if="config.hasActions !== false">
                        <template v-slot="{ row }">
                            <slot name="actions" :entity="row"></slot>
                            <el-tooltip v-if="config.hasBasicActions !== false" placement="top" :content="`edit`">
                              <base-button @click="config.onClickEdit ? config.onClickEdit(row) : null" size="sm" type="info"><i class="fa fa-edit"></i></base-button>
                            </el-tooltip>
                            <el-tooltip v-if="config.hasBasicActions !== false" placement="top" :content="`delete`">
                              <base-button @click="confirmDelete(row)" size="sm" type="danger"><i class="fa fa-trash"></i></base-button>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </b-col>
          </b-row>
          <b-row class="mt-5" v-if="!config.hidePaginate">
               <b-col md="6">
                    <b-pagination
                        @change="(page) => $emit('onPageChanged', page)"
                        v-model="meta.current_page"
                        :total-rows="meta.total"
                        :per-page="meta.per_page"
                        interval="5"
                        first-number
                        last-number/>
               </b-col>
               <b-col md="6" class="d-flex justify-content-end align-items-center">
                   <span class="text-sm mr-2">Per Page</span>
                   <el-select size="mini" v-model="perPage" @change="(val) => $emit('onLimitChanged', val)"
                        placeholder="Simple select">
                        <el-option v-for="option in [10, 20, 50]"
                                :key="option"
                                :label="option"
                                :value="option">
                        </el-option>
                    </el-select>
               </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </div>
    <slot name="others"/>
  </div>
</template>

<script>
import {
    Table,
    TableColumn,
    DropdownMenu,
    DropdownItem,
    Dropdown,
    Select,
    Option
} from 'element-ui'
import BaseButton from '../BaseButton.vue';

  export default {
    data() {
        return {
            currentPage: 1,
            perPage: 10
        }
    },
    computed: {
      meta() {
        return this.entities.meta;
      }
    },
    methods: {
      indexMethod(index) {
        return this.meta && this.meta.per_page ? this.meta.per_page * (this.meta.current_page - 1)  + (1 + index) : (1 + index);
      },
      goCreatePage() {
        this.$router.push({ name: this.config.createRouteName });
      },
      goToEditPage(entity) {
        this.$router.push({
          name: this.config.editRouteName,
          params: {
            id: entity.id
          }
        });
      },
      confirmDelete(entity) {
        if (this.config.onDeleteEntity && typeof this.config.onDeleteEntity !== 'undefined') {
          this.popupConfirmDelete(() => this.config.onDeleteEntity(entity));
        }
      },
      resolve(path, obj, defaultValue = null) {
          let value = path.split(".").reduce(function (prev, curr) {
              return prev ? prev[curr] : null;
          }, obj || self);
          return value ? value : defaultValue;
      },
    },
    mounted() {
      if (this.config.getEntities && typeof this.config.getEntities !== 'undefined') {
        this.config.getEntities();
      }
    },
    components: {
      BaseButton,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
      [Select.name]: Select,
      [Option.name]: Option
    },
    props: {
        loading: {
          type: Boolean,
        },
        title: {
            type: String,
            required: true
        },
        entities: {
            type: Object,
            default: () => {
              return {
                data: [],
                meta: {}
              }
            }
        },
        columns: {
            type: Array,
            default: () => []
        },
        config: {
          type: Object,
          default: () => {
            return {
              createRouteName: null
            }
          }
        }
    }
  }
</script>

<style lang="scss" scope>
    .cls-form input {
        color: #000 !important;
        font-weight: 600;
    }
</style>